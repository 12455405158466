export const staffAmount = [
  {
    value: '',
    label: '',
  },
  {
    value: 'Menor o igual 10',
    label: 'Menor o igual 10',
  },
  {
    value: 'Menor o igual 30',
    label: 'Menor o igual 30',
  },
  {
    value: 'Menor o igual 60',
    label: 'Menor o igual 60',
  },
  {
    value: 'Menor o igual 100',
    label: 'Menor o igual 100',
  },
  {
    value: 'Más de 100',
    label: 'Más de 100',
  },
];

export const consumerIdealgender = [
  {
    value: '',
    label: '',
  },
  {
    value: 'Masculino',
    label: 'Masculino',
  },
  {
    value: 'Femenino',
    label: 'Femenino',
  },
  {
    value: 'Ambos',
    label: 'Ambos',
  },
];

export const consumerActualgender = [
  {
    value: '',
    label: '',
  },
  {
    value: 'Masculino',
    label: 'Masculino',
  },
  {
    value: 'Femenino',
    label: 'Femenino',
  },
  {
    value: 'Ambos',
    label: 'Ambos',
  },

  {
    value: 'Otros',
    label: 'Otros',
  },
];

export const consumerIdealAge = [
  {
    value: '',
    label: '',
  },
  {
    value: '0 - 12 años',
    label: '0 - 12 años',
  },
  {
    value: '12 - 18 años',
    label: '12 - 18 años',
  },
  {
    value: '18 - 40 años',
    label: '18 - 40 años',
  },
  {
    value: 'Más de 40 años',
    label: 'Más de 40 años',
  },
  {
    value: 'Todas Las Edades',
    label: 'Todas Las Edades',
  },
];

export const consumerIdealLocation = [
  {
    value: '',
    label: '',
  },
  {
    value: 'Nacional',
    label: 'Nacional',
  },
  {
    value: 'America Latina',
    label: 'America Latina',
  },
  {
    value: 'Norte America',
    label: 'Norte America',
  },
  {
    value: 'America Y Europa',
    label: 'America Y Europa',
  },
  {
    value: 'America Y Asia',
    label: 'America Y Asia',
  },

  {
    value: 'Internacional',
    label: 'Internacional',
  },
];

export const consumerIdealSocieEconomic = [
  {
    value: '',
    label: '',
  },
  {
    value: 'Clase Alta',
    label: 'Clase Alta',
  },
  {
    value: 'Clase Media',
    label: 'Clase Media',
  },
  {
    value: 'Clase Baja',
    label: 'Clase Baja',
  },
  {
    value: 'Todas Las Anteriores',
    label: 'Todas Las Anteriores',
  },
];
