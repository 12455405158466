import styled from 'styled-components';

export const FormRadio = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-blue-dark);
  padding: 15px 0px;
  margin-bottom: 15px;
  width: 100%;

  @media screen and (min-width: 600px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const RadioCont = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  @media screen and (min-width: 600px) {
    margin-bottom: 0px;
  }
`;

export const Radio = styled.input`
  height: 18px;
  width: 18px;
  margin-right: 15px;
  cursor: pointer;
  z-index: 2;
`;

export const Label = styled.label`
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: white;
  width: max-content;
`;
