import { CSSProperties } from 'react';
import * as S from './FormRadio.styled';

interface Props {
  formRadioProps?: FormRadioProps;
  className?: string;
  style?: CSSProperties;
}

interface FormRadioProps {
  name: string;
  id: string;
  radioValue: string;
  label: string;
  value: string;

  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FormRadio = ({ formRadioProps, ...restProps }: Props) => {
  const { radioValue, value, label, handleChange, id, ...rest } = formRadioProps || {
    name: 'testname',
    id: 'test',
    radioValue: 'test',
    label: 'test',
    value: '',
  };

  return (
    <S.FormRadio {...restProps}>
      <S.RadioCont>
        <S.Radio
          {...rest}
          id={id}
          type='radio'
          value={radioValue}
          checked={value === radioValue}
          onChange={handleChange}
        />
        <S.Label htmlFor={id}>{label}</S.Label>
      </S.RadioCont>
    </S.FormRadio>
  );
};

export default FormRadio;
